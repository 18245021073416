<template>
  <div class="languages" v-if="languageStore.isLangOverlayOpen">
    <div class="languages__columns">
      <button class="languages__close" @click="closeLanguages">
        <SVG path="icons" svg="icon_close"></SVG>
      </button>
      <h2 class="languages__title roboto--300 h4">{{ translatedFields['lang-headline'][languageStore.currentLang] }}</h2>
      <div v-for="(region,key) in marketsData" :key="key">
        <label class="languages__label">{{ key }}</label>
        <div class="languages__buttons">
          <button class="languages__button roboto--300 p--l" v-for="(market,key) in region" :key="key" :class="{languages__current: languageStore.currentLang === market.market_languages}" @click="selectMarket(market.market_languages,market.market_solr_country_string,market.market_solr_market_string,market.market_name);closeLanguages()">{{ market.market_name }}</button>
        </div>
      </div>
    </div>
    <div class="languages__background" @click="closeLanguages()"></div>
  </div>
</template>

<script setup lang="ts">
import {computed, onBeforeMount, onMounted, ref} from 'vue';
import {addToLocalStorage, getFromLocalStorage} from "@/utils/localstorage";
import SVG from "@/components/SVG.vue";

import {useLanguageStore} from "@/stores/language";
import translatedFields from "../../public/json/translated-fields.json";
const languageStore = useLanguageStore();

const marketsData = ref([]);
// const showLanguages = ref(true); // ref(getFromLocalStorage('market') === null);

const apiBASE = import.meta.env.VITE_API_BASE || '';
const apiUser = import.meta.env.VITE_AUTH_USER || '';
const apiPass = import.meta.env.VITE_AUTH_PASS || '';

const closeLanguages = () => {
  languageStore.isLangOverlayOpen = false;
};

const selectMarket = (lang = 'de', market = 'DIN', country = 'DE', countryLong = 'Deutschland') => {
  addToLocalStorage('market', {lang, market, country, countryLong});
  // closeLanguages()
  languageStore.currentCountry = countryLong;
  languageStore.currentLang = lang;

  // TODO: CHECK BOOKMARKS AND REMOVE PRODUCTS THAT ARE NOT IN THE SELECTED MARKET
};

onBeforeMount(async () => {
  const localStorageMarket = getFromLocalStorage('market');

  const requestURLS = [
      {
        'url': apiBASE + '/get/markets',
      },
    ];
  
    for (const request of requestURLS) {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa(apiUser + ':' + apiPass)
        }
      };

      try {
        const response = await fetch(request.url, requestOptions);
        marketsData.value = await response.json();
      } catch (error) {
        // console.error('Error:', error);
        throw error;
      }
    }
});
</script>

<style scoped lang="scss">
.languages {
  --background: rgba(51, 51, 51, 0.48);
  --villeroy: rgba(2, 62, 132, 1);
  --white: rgba(255, 255, 255, 1);
  --hover: rgba(25, 107, 220, 1);
  --dark: rgba(51, 51, 51, 1);

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;

  .languages__columns {
    position: absolute;
    top: 60px;
    right: 60px;
    padding: 56px 40px;
    background-color: var(--white);
    z-index: 1;

    .languages__close {
      appearance: none;
      border: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 12px;
      right: 12px;
      width: 36px;
      height: 36px;
      cursor: pointer;
      border-radius: 100%;
      background-color: var(--dark);
      transition: color 350ms ease;

      &:hover {
        color: var(--villeroy)
      }
    }

    .languages__title {
      margin: 0 0 24px 0;
    }

    .languages__label {
      display: inline-block;
      color: var(--villeroy);
      margin: 0 0 16px 0;
    }

    .languages__buttons {
      display: grid;
      grid-template-columns: max-content;
      gap: 8px;

      .languages__button {
        appearance: none;
        border: none;
        padding: 0;
        text-align: left;
        cursor: pointer;
        background-color: transparent;
        transition: color 350ms ease;

        &:hover {
          color: var(--villeroy);
        }
      }

      .languages__current {
        color: var(--villeroy);
        font-weight: 400;
      }
    }
  }

  .languages__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    cursor: alias;
  }
}

@media screen and (max-width: 768px) {
  .languages {
    .languages__columns {
      top: 24px;
      right: 24px;
      bottom: 24px;
      left: 24px;
    }
  }
}
</style>