import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useRoutesStore = defineStore('routes', () => {
    const navRoutes = [
        {name: 'style', path: '/style'},
        {name: 'look', path: '/look'},
        {name: 'room', path: '/room'},
        {name: 'result', path: '/result'}
    ];
    // const addedBookmarks = ref([]);
    const allowedRoutes = ref([]);
    const finishedRoutes = ref([]);
    const toolHasStarted = ref(false);
    return { navRoutes, allowedRoutes, finishedRoutes };
})