<template>
    <button v-bind:alt="translatedFields['lang-switcher'][languageStore.currentLang]" class="lang-settings" @click="openLangOverlay">
        <svg alt="" width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95a15.65 15.65 0 00-1.38-3.56A8.03 8.03 0 0118.92 8zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56A7.987 7.987 0 015.08 16zm2.95-8H5.08a7.987 7.987 0 014.33-3.56A15.65 15.65 0 008.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 01-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z" id="a"></path>
            </defs>
            <g fill="none" fill-rule="evenodd">
                <mask id="b" fill="white">
                <use href="#a"></use>
                </mask>
                <g class="lang-settings__svg" mask="url(#b)" fill="#333333">
                    <path d="M0 0h24v24H0z"></path>
                </g>
            </g>
        </svg>
        <span>
            {{ languageStore.currentCountry }}
        </span>
    </button>
</template>

<script setup lang="ts">
import translatedFields from "../../public/json/translated-fields.json";
import {useLanguageStore} from "@/stores/language";
const languageStore = useLanguageStore();

const openLangOverlay = () => {
  languageStore.isLangOverlayOpen = true;
};
</script>

<style lang="scss">
.lang-settings {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: solid 1px var(--vb_other_grey);
    border-radius: 4px;
    padding: 8px 20px;
    color: var(--vb_other_grey);
    background-color: var(--vb_white);
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    transition: color 350ms ease, border-color 350ms ease-in-out;
    svg {
        width: 24px;
        height: 24px;
    }
    .lang-settings__svg {
        fill: var(--vb_other_grey);
        transition: fill 350ms ease-in-out;
    }
    &:hover {
        color: var(--vb_blue_hover);
        border-color: var(--vb_blue_hover);
        .lang-settings__svg {
            fill: var(--vb_blue_hover);
        }
    }
}
@media screen and (max-width: 1024px) {
    .lang-settings {
        order: 0;
        font-size: 14px;
        svg {
            width: 20px;
            height: 20px;
        }
    }
}
</style>